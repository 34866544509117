import loadable from "@loadable/component"

import ArrowDown from "./ArrowDown";
import Cashier from "./Cashier";
import Error from "./Error";
import ExitFullscreen from "./ExitFullscreen";
import Fullscreen from "./Fullscreen";
import Heart from "./Heart";
import HeartOutline from "./HeartOutline";
import HelpChat from "./HelpChat";
import Home from "./Home";
import Notice from "./Notice";
import Phone from "./Phone";
import Reload from "./Reload";
import Warning from "./Warning";
import PendingWithdrawalsSet from "./PendingWithdrawalsSet";
import InsufficientBalanceSet from "./InsufficientBalanceSet"

import "./Icon.css";

const Aplay = loadable(() => import("./Aplay"));
const Astrajam = loadable(() => import("./Astrajam"));
const AstrajamCW = loadable(() => import("./AstrajamCW"));
const Cricv = loadable(() => import("./Cricv"));
const DailyChallenge = loadable(() => import("./DailyChallenge"));
const DailyChallengeArrowClosedIcon = loadable(() => import("./DailyChallengeArrowClosedIcon"));
const DailyChallengeDefaultSwiperIcon = loadable(() => import("./DailyChallengeDefaultSwiper"));
const DailyChallengeErrorIcon = loadable(() => import("./DailyChallengeError"));
const DailyChallengeHard = loadable(() => import("./DailyChallengeHard"));
const DailyChallengeInfoActivationIcon = loadable(() => import("./DailyChallengeInfoActivation"));
const DailyChallengeMedium = loadable(() => import("./DailyChallengeMedium"));
const DailyChallengeReceivedIcon = loadable(() => import("./DailyChallengeReceivedIcon"));
const DailyChallengeSuccessSwiperIcon = loadable(() => import("./DailyChallengeSuccessSwiper"));
const Drift = loadable(() => import("./Drift"));
const Frank = loadable(() => import("./Frank"));
const FrankCW = loadable(() => import("./FrankCW"));
const FrankUserProgress = loadable(() => import("./FrankUserProgress"));
const Mrbit = loadable(() => import("./Mrbit"));
const MrbitCW = loadable(() => import("./MrbitCW"));
const MrbitUserProgress = loadable(() => import("./MrbitUserProgress"));
const OneClick = loadable(() => import("./OneClick"));
const QuickLock = loadable(() => import("./QuickLock"));
const SchevronLeft = loadable(() => import("./SchevronLeft"));
const Sjalvtest = loadable(() => import("./Sjalvtest"));
const Slotv = loadable(() => import("./Slotv"));
const SlotvCW = loadable(() => import("./SlotvCW"));
const Spelgranser = loadable(() => import("./Spelgranser"));
const Spelpaus = loadable(() => import("./Spelpaus"));
const Spelstopp = loadable(() => import("./Spelstopp"));
const Speltimer = loadable(() => import("./Speltimer"));
const Tournament = loadable(() => import("./Tournament"));
const WagerProgress = loadable(() => import("./WagerProgress"));

export default {
  Aplay,
  ArrowDown,
  Astrajam,
  AstrajamCW,
  Cashier,
  Cricv,
  DailyChallenge,
  DailyChallengeArrowClosedIcon,
  DailyChallengeDefaultSwiperIcon,
  DailyChallengeErrorIcon,
  DailyChallengeHard,
  DailyChallengeInfoActivationIcon,
  DailyChallengeMedium,
  DailyChallengeReceivedIcon,
  DailyChallengeSuccessSwiperIcon,
  Drift,
  Error,
  ExitFullscreen,
  Frank,
  FrankCW,
  FrankUserProgress,
  Fullscreen,
  Heart,
  HeartOutline,
  HelpChat,
  Home,
  InsufficientBalanceSet,
  Mrbit,
  MrbitCW,
  MrbitUserProgress,
  Notice,
  OneClick,
  PendingWithdrawalsSet,
  Phone,
  QuickLock,
  Reload,
  SchevronLeft,
  Sjalvtest,
  Slotv,
  SlotvCW,
  Spelgranser,
  Spelpaus,
  Spelstopp,
  Speltimer,
  Tournament,
  WagerProgress,
  Warning,
};
